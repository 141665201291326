// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Make sure to include Switch
import SignIn from "./components/auth/SignIn";
import AuthDetails from "./components/AuthDetails";
import Dashboard from "./components/Dashboard";
import Help from "./components/project/Help";
import List from "./components/project/List";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import "./App.css";
import ChooseProblemMenu from "./components/project/creatingProblem/ChooseProblemMenu";
import Ucebna from "./components/project/creatingProblem/problem/Ucebna";
import Chodba from "./components/project/creatingProblem/problem/Chodba";
import Telocvicna from "./components/project/creatingProblem/problem/Telocvicna";
import Jine from "./components/project/creatingProblem/problem/Jine";


function App() {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = (user) => {
    setAuthUser(user);
  };

  const handleSignOut = () => {
    setAuthUser(null);
  };

  if (authUser) {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/"  element={<Dashboard userEmail={authUser.email} onSignOut={handleSignOut} />} />
            <Route path="/help" element={<Help />} />
            <Route path="*" element={<h1>Not Found</h1>} />
            <Route path="/create" element={<ChooseProblemMenu />} />
            <Route path="/list" element={<List />} /> 
            <Route path="/create/ucebna" element={<Ucebna />} /> 
            <Route path="/create/chodba" element={<Chodba />} /> 
            <Route path="/create/telocvicna" element={<Telocvicna />} /> 
            <Route path="/create/jine" element={<Jine />} /> 
            </Routes>
        </Router>
      </div>
    );
  }

  return (
    <div className="App">
      <SignIn onSignIn={handleSignIn} />
      {!authUser && <AuthDetails />}
    </div>
  );
}

export default App;
