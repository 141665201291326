// SignIn.jsx

import React, { useState } from "react";
import { auth } from "./../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import "./SignIn.css";

const SignIn = ({ onSignIn }) => {
  const defaultDomain = "@creativehill.cz";
  const defaultDomainLogin = " @creativehill.cz";

  const [emailPrefix, setEmailPrefix] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const mapFirebaseErrorToMessage = (errorCode) => {
    switch (errorCode) {
      case "auth/invalid-email":
        return "Invalid Username";
      case "auth/invalid-login-credentials":
        return "Invalid Login";
      default:
        return "Fatal Error";
    }
  };

  const handleSignIn = (e) => {
    e.preventDefault();

    const email = emailPrefix + defaultDomain;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
        onSignIn(user);
      })
      .catch((error) => {
        console.error(error);

        const errorMessage = mapFirebaseErrorToMessage(error.code);
        setError(errorMessage);

        setTimeout(() => {
          setError(null);
        }, 2000);
      });
  };

  return (
    <div className="sign-in-container">
      <h1>Log In</h1>
      <form onSubmit={handleSignIn}>
        {error && <p className="error-message">{error}</p>}
        <div className="input-group">
          <label></label>
          <input
            type="text"
            placeholder="Username"
            value={emailPrefix}
            onChange={(e) => setEmailPrefix(e.target.value)}
          />
          {defaultDomainLogin}
        </div>
        <div className="input-group">
          <label></label>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="login">
          Log In
        </button>
      </form>
    </div>
  );
};

export default SignIn;
