import React from 'react';
import { Link } from 'react-router-dom';
import './Help.css';

const Help = () => {
  return (
    <div>
      <Link to="/" className="exit-button-black">
          Exit
        </Link>
      <h1>Wellcome in CreativeFIX program!</h1>
      <p>Welcome to the help page!</p>
      <p>If you have any questions or need assistance, please contact our support team.</p>
      <p>Our support team is available 24/7.</p>
      <p>Phone: +48 123 456 789</p>
      <p>Email: test@test.com </p>
    </div>
  );
};

export default Help;
