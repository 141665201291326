import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from './../../firebase';
import './List.css';

const List = () => {
  const [user, setUser] = useState(null);
  const [data, setData] = useState([]);
  const placeMapping = {
    1: 'Ucebna',
    2: 'Chodba',
    3: 'Telocvicna',
    4: 'Jine'
  };

  const statusMapping = {
    1: 'New',
    2: 'In Progress',
    3: 'Done',
    4: 'Paused'
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetch('http://localhost:8081/api/data')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setData(data);
      })
      .catch(error => {
        console.error('Error creating data:', error);
        alert("API is not working properly. ERROR: ---" + error + "---");
    });
    }, []);

  const allowedUserEmails = ['admin@creativehill.cz', 'admin2@example.com', 'admin3@example.com'];

  if (!user || !allowedUserEmails.includes(user.email)) {
    return (
      <div>
        <Link to="/" className="exit-button-blck">
          Exit
        </Link>
        <p>You don't have permission to access this page.</p>
      </div>
    );
  }

  const handleDelete = (id) => {
    fetch(`http://localhost:8081/api/data/${id}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        setData(data.filter(item => item.id !== id));
      })
      .catch(error => console.error('Error deleting data:', error));
  };

  const handleStatusChange = (id, status) => {
    fetch(`http://localhost:8081/api/data/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ status: status })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        setData(data.map(item => item.id === id ? { ...item, status: status } : item));
      })
      .catch(error => console.error('Error updating status:', error));
  };

  return (
    <div className="container">
      <Link to="/" className="exit-button-black">
        Exit
      </Link>
      <h1 className="heading">List of Problems</h1>
      <table className="problem-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Task</th>
            <th>Note</th>
            <th>Place</th>
            <th>Status</th>
            <th>Action</th>
            <th>Set Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.task}</td>
              <td>{item.note}</td>
              <td>{placeMapping[item.place]}</td>
              <td>{statusMapping[item.status]}</td>
              <td>
                <button onClick={() => handleDelete(item.id)}>Delete</button>
              </td>
              <td>
                <select onChange={(e) => handleStatusChange(item.id, parseInt(e.target.value))}>
                  <option>Choose one</option>
                  <option disabled>----------</option>
                  <option value={1}>New</option>
                  <option value={2}>In Progress</option>
                  <option value={3}>Done</option>
                  <option value={4}>Paused</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;
