import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './problem.css';

const Telocvicna = () => {
    const [task, setTask] = useState('');
    const [note, setNote] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('http://localhost:8081/api/data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                task: task,
                note: note, 
                place: 3,
                status: 1
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                window.location.href = "/";
            })
            .catch(error => {
                console.error('Error creating data:', error);
                alert("API is not working properly. ERROR: ---" + error + "---");
            });
    };

    const handleTaskChange = (e) => {
        setTask(e.target.value);
    };
    const handleNoteChange = (e) => {
        setNote(e.target.value);
    };

    return (
        <div className="create-ucebna">
            <Link to="/" className="exit-button-black">Exit</Link>
            <div className="">
                <form onSubmit={handleSubmit}>
                    <label htmlFor="task">Task:</label>
                    <input type="text" id="task" name="task" value={task} onChange={handleTaskChange} />
                    <br />
                    <br />
                    <label htmlFor="note">Note:</label>
                    <input type="text" id="note" name="note" value={note} onChange={handleNoteChange} />
                    <br />
                    <br />
                    <button type="submit">Submit</button>
                    <button><Link to="/" className="">Cancel</Link></button>
                </form>
            </div>
        </div>
    );
};

export default Telocvicna;