import React from 'react';
import { Link } from 'react-router-dom';
import './ChooseProblemMenu.css';

const ChooseProblemMenu = () => {
  return (
    <div className="dark-page"><Link to="/" className="exit-button">
          Exit
        </Link>
      <div className="button-row">
        
      </div>
      <div className="button-row">
      <button className="dark-button"><Link to="/create/ucebna">Učebna</Link></button>
      <button className="dark-button"><Link to="/create/chodba">Chodba</Link></button>
      </div>
      <div className="button-row">
      <button className="dark-button"><Link to="/create/telocvicna">Tělocvična</Link></button>
      <button className="dark-button"><Link to="/create/jine">Jiné</Link></button>
      </div>
    </div>
  );
};

export default ChooseProblemMenu;
