import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { auth } from './../firebase';
import { signOut } from 'firebase/auth';
import './Dashboard.css'; // Import the CSS file

const Dashboard = ({ userEmail, onSignOut }) => {
  const adminEmails = useMemo(() => ['admin@example.com', 'admin@creativehill.cz', '21090ah@creativehill.cz'], []);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Check if the current user has admin privileges based on the list of admin emails
    if (adminEmails.includes(userEmail)) {
      setIsAdmin(true);
    }
  }, [userEmail, adminEmails]);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        onSignOut();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  
  return (
    <div className="dashboard-container">
      <h1 className="dashboard-title">Creative<b>FIX</b></h1>
      <p className="user-info">Signed in as <b>{userEmail}</b></p>
      <p className="user-info">Permissions: <b>{isAdmin ? 'Admin' : 'User'}</b></p>
      <div className="button-container">
        <Link to="/create" className="dashboard-button">Create Problem</Link>
        {isAdmin && <Link to="/list" className="dashboard-button">Open List Of Problems</Link>}
        <button className="dashboard-button">Settings</button>
        <Link to="/help" className="dashboard-button">Help</Link>
      </div>
      <button onClick={handleSignOut} className="sign-out-button">
        Sign Out
      </button>
    </div>
  );
};

export default Dashboard;
